<template>
  <div class="page">
    <el-popover
        placement="bottom-end"
        width="600"
        :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
        v-model="visible">
      <el-button slot="reference">搜索</el-button>
    </el-popover>
    <el-tabs style="margin-top: 10px;margin-bottom: 10px" v-model="searchObj.state" type="card" @tab-click="tabChange">
      <el-tab-pane name="0">
        <template slot="label">
          <span>全部订单</span>
          <span class="count" v-if="summary['0']">({{summary['0']}})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="1">
        <template slot="label">
          <span>询盘单</span>
          <span class="count" v-if="summary['1']">({{summary['1']}})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="2">
        <template slot="label">
          <span>成功单</span>
          <span class="count" v-if="summary['2']">({{summary['2']}})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="3">
        <template slot="label">
          <span>拒绝单</span>
          <span class="count" v-if="summary['3']">({{summary['3']}})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="4">
        <template slot="label">
          <span>待客户回复</span>
          <span class="count" v-if="summary['4']">({{summary['4']}})</span>
        </template>
      </el-tab-pane>
      <el-tab-pane name="5">
        <template slot="label">
          <span>待平台回复</span>
          <span class="count" v-if="summary['5']">({{summary['5']}})</span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <inquiryTableList :orderList="list" type="list"/>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj['per-page']" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>

<script>
import inquiryTableList from './components/inquiryTableList'
import {apiGetInquiryList} from "@/request/api";
export default {
  name: "inquiry",
  components: {
    inquiryTableList
  },
  data(){
    return{
      visible: false,
      pageCount:0,
      list:[],
      searchObj:{
        state: '0',
        page: 1,
        'per-page': 10,
      },
      summary:{}
    }
  },
  created() {
    this.getData()
  },
  methods:{
    tabChange(){
      this.searchObj.page = 1
      this.getData()
    },
    pageChangeHandle(){
      this.getData()
      window.screenTop = 0;
    },
    getData(){
      apiGetInquiryList(this.searchObj).then(res => {
        if(res.code == 200){
          this.list = res.data.list
          this.pageCount = res.data.count
          this.summary = res.data.summary
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
  /*margin-bottom: 80px;*/
}
.count {
  color:#F56C6C;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
</style>